var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "userManagement", staticClass: "vehicle-management" },
    [
      _c(
        "div",
        { ref: "functionButtons", staticClass: "function-buttons" },
        [
          _vm.$store.state.menu.nowMenuList.indexOf("新增") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.onAddVehicle }
                },
                [_vm._v("新增")]
              )
            : _vm._e(),
          _vm.$store.state.menu.nowMenuList.indexOf("导入") >= 0
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.importExcel }
                },
                [_vm._v("导入")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { ref: "form", staticClass: "form-area" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: {
                inline: true,
                model: _vm.form,
                "label-position": "left",
                "label-width": "96px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车牌号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车牌号" },
                    model: {
                      value: _vm.form.cph,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "cph",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.cph"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车架号：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入车架号" },
                    model: {
                      value: _vm.form.vehicleNo,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "vehicleNo",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.vehicleNo"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属企业：" } },
                [
                  _c("companySelect", {
                    ref: "companySelect",
                    attrs: {
                      clearable: true,
                      companyTree: _vm.companyList,
                      valueName: _vm.valueName,
                      value: _vm.value
                    },
                    on: { getValue: _vm.getGroupId }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车辆类别：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择车辆类别" },
                      model: {
                        value: _vm.form.vehicleClassAssanation,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "vehicleClassAssanation", $$v)
                        },
                        expression: "form.vehicleClassAssanation"
                      }
                    },
                    _vm._l(_vm.vehicleClassAssanationList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "车辆状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "请选择车辆状态" },
                      model: {
                        value: _vm.form.vehicleStatus,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "vehicleStatus", $$v)
                        },
                        expression: "form.vehicleStatus"
                      }
                    },
                    _vm._l(_vm.vehicleStatusList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { label: item.dictValue, value: item.dictCode }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.onSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        disabled: _vm.tableData.length === 0,
                        loading: _vm.downLoadStatus
                      },
                      on: { click: _vm.exportData }
                    },
                    [_vm._v("导出")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            stripe: "",
            height: _vm.tableHeight,
            "row-class-name": _vm.rowColor
          }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", label: "序号", width: "60" }
          }),
          _c("el-table-column", { attrs: { prop: "cph", label: "车牌号" } }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: "所属企业",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: {
              prop: "type",
              label: "车辆类别",
              "show-overflow-tooltip": true
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.vehicleClassListObj[
                            Number(scope.row.vehicleClassAssanation)
                          ]
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: {
              prop: "vehicleNo",
              label: "车架号",
              "show-overflow-tooltip": true
            }
          }),
          _c("el-table-column", {
            attrs: { prop: "vehicleStatus", label: "车辆状态", width: "80" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.vehicleStatusObj[Number(scope.row.vehicleStatus)]
                        ) +
                        " "
                    )
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "150px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.$store.state.menu.nowMenuList.indexOf("编辑") >= 0
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { color: "#007aff" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.changeVehicleStatus(scope.row)
                              }
                            }
                          },
                          [_vm._v("更改车辆状态")]
                        )
                      : _vm._e(),
                    _vm.$store.state.menu.nowMenuList.indexOf("编辑") >= 0
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              size: "small",
                              sort: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.onUpdateVehicle(scope.row)
                              }
                            }
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { ref: "pagination", staticClass: "pagination" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.total,
              "page-size": _vm.form.pageSize,
              "current-page": _vm.form.currentPage
            },
            on: {
              "size-change": _vm.onSizeChange,
              "current-change": _vm.onCurrentChange
            }
          })
        ],
        1
      ),
      _vm.dialogVisible
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.title,
                visible: _vm.dialogVisible,
                width: "70%",
                "custom-class": "add-vehicle-dialog"
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisible = $event
                }
              }
            },
            [
              _c("addVehicle", {
                ref: "addVehicle",
                attrs: { vehicleNo: _vm.vehicleNo, rowData: _vm.rowData },
                on: { saveEnd: _vm.saveEnd, onLoading: _vm.onLoading }
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "small" },
                      on: {
                        click: function($event) {
                          _vm.dialogVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "small",
                        loading: _vm.editLoading
                      },
                      on: { click: _vm.onSave }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.downVisible
        ? _c(
            "el-dialog",
            {
              attrs: { title: "导入", visible: _vm.downVisible, width: "30%" },
              on: {
                "update:visible": function($event) {
                  _vm.downVisible = $event
                }
              }
            },
            [
              _c("upload-file", {
                staticClass: "import",
                attrs: { url: "base/vehicle/importDriver", type: 0 },
                on: { uploadSuccess: _vm.uploadSuccess }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改车辆状态",
            width: "300px",
            visible: _vm.statusVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.statusVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-width": "70px" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "车辆状态：" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.changeStatus,
                        callback: function($$v) {
                          _vm.changeStatus = $$v
                        },
                        expression: "changeStatus"
                      }
                    },
                    _vm._l(_vm.vehicleStatusList, function(item) {
                      return _c("el-option", {
                        key: item.dictCode,
                        attrs: { value: item.dictCode, label: item.dictValue }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { "label-width": "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function($event) {
                          _vm.statusVisible = false
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "small",
                        disabled: !_vm.changeStatus,
                        type: "primary"
                      },
                      on: { click: _vm.submitChange }
                    },
                    [_vm._v("确认")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.orderHelperSataus
        ? _c("order-helper", {
            ref: "orderHelper",
            attrs: { rowData: _vm.changeStatusTarget },
            on: {
              closeDia: function(v) {
                return (this$1.orderHelperSataus = v)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }